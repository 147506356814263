// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"97733bfa8ff0c16466c37b0e121c412498bb3b46"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs"

const DSN_DESTINATION: string = process.env.NODE_ENV === "development" ? "local" : "prod"

Sentry.init({
  dsn: `http${DSN_DESTINATION === "prod" ? "s" : ""}://4613834dd1094c5a939e65281ea4094c@o3017525720594717.ingest.us.${DSN_DESTINATION === "local" ? "localhost:8000" : "getdecipher.com"}/5382930619793880`,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
})
